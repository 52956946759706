// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-enrollment-national-general-index-tsx": () => import("./../../../src/pages/enrollment/national-general/index.tsx" /* webpackChunkName: "component---src-pages-enrollment-national-general-index-tsx" */),
  "component---src-pages-enrollment-universal-index-tsx": () => import("./../../../src/pages/enrollment/universal/index.tsx" /* webpackChunkName: "component---src-pages-enrollment-universal-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-page-3-index-tsx": () => import("./../../../src/pages/page-3/index.tsx" /* webpackChunkName: "component---src-pages-page-3-index-tsx" */),
  "component---src-pages-quotes-index-tsx": () => import("./../../../src/pages/quotes/index.tsx" /* webpackChunkName: "component---src-pages-quotes-index-tsx" */),
  "component---src-pages-test-slider-index-tsx": () => import("./../../../src/pages/test-slider/index.tsx" /* webpackChunkName: "component---src-pages-test-slider-index-tsx" */)
}

